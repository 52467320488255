import React from "react"
import styled from "styled-components"
import { devices } from "../styles/media"

function Jumbo({ children }) {
  return (
    <Container>
      <Background>
        <ButtonBar>
          <Button />
          <Button />
          <Button />
        </ButtonBar>
        {children}
      </Background>
    </Container>
  )
}

export default Jumbo

const Container = styled("div")`
  display: block;
  text-align: center;
`
const ButtonBar = styled("div")`
  position: absolute;
  left: 7px;
  top: 7px;
  line-height: 1;
`
const Button = styled("div")`
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.background};
  &:not(:last-child) {
    margin-right: 10px;
  }
`
const Background = styled("div")`
  display: inline-block;
  padding: 38px 50px 25px 50px;
  background-color: ${({ theme }) => theme.contrast};
  color: ${({ theme }) => theme.background};
  position: relative;
  @media ${devices.tablet} {
    display: block;
    padding: 38px 0px 25px 0px;
  }
`
