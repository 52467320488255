import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 300)
        }
      }
    }
  `)

  return <Img image={data.placeholderImage.childImageSharp.gatsbyImageData} />
}

export default Image
