import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import styled, { css, keyframes } from "styled-components"

import Layout from "../components/layout"
import ProfilePic from "../components/profile"
import Anchor from "../components/Anchor"
import Jumbo from "../components/Jumbo"
import { devices } from "../styles/media"

function Me() {
  const {
    dbsIcon,
    ritualIcon,
    techladiesIcon,
    singlifeIcon,
  } = useStaticQuery(graphql`
    query {
      singlifeIcon: file(relativePath: { eq: "singlife-favicon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 20)
        }
      }
      dbsIcon: file(relativePath: { eq: "dbs-icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 20)
        }
      }
      techladiesIcon: file(relativePath: { eq: "techladies-icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 20)
        }
      }
      ritualIcon: file(relativePath: { eq: "ritual-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 20)
        }
      }
    }
  `)
  return (
    <Layout>
      <Jumbo>
        <JumboContent>{"> Hello, World"}</JumboContent>
      </Jumbo>
      <Container>
        <Main>
          <Section>
            <Description>
              My name is Thomas Chia and I make things with software.
              Champion of serverless and the cloud. Builder of teams.
              <br />
            </Description>
            <DescriptionLight>
              Currently &#8212; CTO, Chocolate Finance.
            </DescriptionLight>
            <SelectProjectsContainer>
              <SelectProjectsTitle>Done & Doing:</SelectProjectsTitle>
              <ProjectsList>
                <ProjectItem delay={0}>
                  <Img image={singlifeIcon.childImageSharp.gatsbyImageData} />
                  <ProjectName>
                    <Anchor href="https://www.singlife.com">Singlife</Anchor>
                  </ProjectName>
                  <ProjectURL>Web & Cloud Migrations</ProjectURL>
                </ProjectItem>
                <ProjectItem delay={200}>
                  <Img image={dbsIcon.childImageSharp.gatsbyImageData} />
                  <ProjectName>
                    <Anchor href="https://www.dbs.com.sg">DBS Bank Ltd.</Anchor>
                  </ProjectName>
                  <ProjectURL>UI Frameworks & Web Architecture</ProjectURL>
                </ProjectItem>
                <ProjectItem delay={400}>
                  <Img
                    image={techladiesIcon.childImageSharp.gatsbyImageData}
                    style={{ borderRadius: 4 }}
                  />
                  <ProjectName>
                    <Anchor href="http://www.techladies.co">TechLadies</Anchor>
                  </ProjectName>
                  <ProjectURL>Bootcamp Tech Lead (Volunteer)</ProjectURL>
                </ProjectItem>
                <ProjectItem delay={600}>
                  <Img image={ritualIcon.childImageSharp.gatsbyImageData} />
                  <ProjectName>
                    <Anchor href="https://www.ritualgym.com">Ritual Gym</Anchor>
                  </ProjectName>
                  <ProjectURL>Software Developer</ProjectURL>
                </ProjectItem>
              </ProjectsList>
            </SelectProjectsContainer>
          </Section>
          <Section>
            <ImageContainer>
              <ProfilePic />
            </ImageContainer>
          </Section>
        </Main>
      </Container>
    </Layout>
  )
}

export default Me

const boxShadow = css`
  box-shadow: 0px 0px 8px lightgrey;
`
const fadeInKf = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const fadeIn = ({ delay }) => css`
  opacity: 0;
  animation: 500ms ease-in-out ${fadeInKf} ${delay}ms forwards;
`
const Container = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`

const JumboContent = styled("h1")`
  font-family: Playfair Display;
  font-size: 5rem;
  margin: 0px;
  @media ${devices.tablet} {
    font-size: 3.5rem;
  }
  @media ${devices.mobileL} {
    font-size: 3rem;
  }
  @media ${devices.mobileM} {
    font-size: 2.8rem;
  }
`
const Description = styled("h3")`
  font-family: "Playfair Display", sans-serif;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.contrast};
  @media ${devices.tablet} {
    text-align: center;
  }
`
const DescriptionLight = styled("span")`
  color: ${({ theme }) => theme.secondary1};
  font-size: 1rem;
  @media ${devices.tablet} {
    display: block;
    text-align: center;
  }
`
const SelectProjectsContainer = styled("div")`
  margin: 20px 0px 50px 0px;
`
const SelectProjectsTitle = styled("p")`
  color: ${({ theme }) => theme.secondary2};
  font-size: 0.7rem;
  margin: 0px;
`
const ProjectsList = styled("ul")`
  list-style-type: none;
  margin: 0px;
  padding: 0px;
`
const ProjectItem = styled("li")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  padding: 15px 0px;
  border-bottom: ${({ theme }) => `solid 1px ${theme.secondary3}`};
  font-size: 0.8rem;
  flex-wrap: wrap;
  ${fadeIn};
`
const ProjectName = styled("p")`
  display: inline-block;
  font-weight: 700;
  margin: 0px 10px;
  color: ${({ theme }) => theme.contrast};
`
const ProjectURL = styled(ProjectName)`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary2};
  margin-left: auto;
`
const ImageContainer = styled("div")`
  min-width: 300px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  ${boxShadow};
  @media ${devices.tablet} {
    width: 200px;
    min-width: unset;
    margin-right: auto;
    margin-left: auto;
    margin-top: 32px;
    margin-bottom: 20px;
  }
`
const Main = styled("section")`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  margin-top: 90px;
  max-width: 1000px;
  @media ${devices.tablet} {
    margin-top: 0px;
    flex-direction: column-reverse;
  }
`
const Section = styled("section")`
  &:not(:last-child) {
    padding-right: 30px;
  }
  @media ${devices.tablet} {
    width: 100%;
    &:not(:last-child) {
      padding-right: 0px;
    }
  }
`
